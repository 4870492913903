/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './CalculatorAside.scss';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';

const CalculatorAside = () => (
  <Tabs className="Tabs" defaultIndex={0}>
    <TabList className="Tabs__nav">
      <Tab
        className="Tabs__nav__item"
        selectedClassName="is-active"
      >
        Features
      </Tab>
      <Tab
        className="Tabs__nav__item"
        selectedClassName="is-active"
      >
        Format
      </Tab>
    </TabList>
    <div className="Tabs__content">
      <TabPanel
        className="Tabs__content__item"
        selectedClassName="is-active"
      >
        <ul>
          <li><strong>FREE</strong> bibliography page</li>
          <li><strong>FREE</strong> title page</li>
          <li><strong>FREE</strong> formatting (APA, MLA, Harvard, Chicago/Turabian)</li>
          <li>24x7 support</li>
          <li>Part-by-part payment</li>
          <li>PowerPoint slides</li>
          <li>Review your writer’s samples</li>
        </ul>
      </TabPanel>
      <TabPanel
        className="Tabs__content__item"
        selectedClassName="is-active"
        forceRender
      >
        <ul>
          <li>Approx. <strong>275 words / page</strong></li>
          <li>Font: 12 point Arial/Times New Roman</li>
          <li>Double and single spacing</li>
        </ul>
      </TabPanel>
    </div>
  </Tabs>
);

export default CalculatorAside;
