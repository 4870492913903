/* globals Calc */
// @todo enable next eslint rules
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-new */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from '../Spinner';

import './CalculatorSection.scss';
import './Calculator.scss';

class Calculator extends Component {
  state = {
    calcLoaded: false,
  };

  payInTwoCheckboxRef = React.createRef(null);

  componentDidMount() {
    const payInTwoCheckbox = this.payInTwoCheckboxRef.current;

    if (payInTwoCheckbox) payInTwoCheckbox.checked = payInTwoCheckbox.defaultChecked;

    function getScript(url, callback) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.querySelector('[data-calc-mini]').appendChild(script);
    }

    if (!document.querySelector('script[src="/assets/js/calc.js"]')) {
      getScript('/assets/js/calc.js', () => {
        this.getCalc();
      });
    }
  }

  getCalc = () => {
    const { academicLevel, paperType, pages } = this.props;

    new Calc({
      ID: '0',
      calculatorType: 'DefaultCalculator',
      format: 'json',
      callback: (res) => {
        this.setState({
          calcLoaded: !res.isLoading,
        });
      },
      options: {
        deafault: {
          deadlinesDefault: [66279, 66289, 66299, 66309, 66319],
          academicLevelDefault: academicLevel,
          paperTypesDefault: paperType,
          pagesDefault: pages,
        },
      },
    });
  };

  render() {
    const { calcLoaded } = this.state;

    return (
      <div className="CalcFull" data-calc-mini>
        <form
          name="uvocalculator"
          className={classNames('Calc', !calcLoaded && 'loading', calcLoaded && 'loaded')}
          method="get"
          action="/"
          data-calculator-form
        >
          <select name="topcatId" style={{ display: 'none' }} data-discipline-select />
          <div className="Calc__wrapper">
            <div className="Calc__cell Calc__cell--type-of-paper">
              <label htmlFor="calc-type-of-paper" className="Calc__label select">
                <span>Type of paper</span>
                <select id="calc-type-of-paper" name="paperTypeId" data-academic-level-select />
              </label>
            </div>
            <div className="Calc__cell Calc__cell--academic-level">
              <label htmlFor="calc-academic-level" className="Calc__label select">
                <span>Academic level</span>
                <select id="calc-academic-level" data-academic-level-select-mobile />
              </label>
            </div>
            <div className="Calc__cell Calc__cell--academic-level-select">
              <div className="Calc__select Calc__select--no-gradient select">
                <div className="Calc--hidden" data-academic-level-control-upgrade />
                <div className="uvocalc--academic_level_content" data-tariff-control-upgrade />
              </div>
            </div>
            <div className="Calc__cell--academic-level-mobile">
              <div className="Calc__academic-level--mobile" data-tariff-control-mobile />
            </div>
            <div className="Calc__tip" style={{ display: 'none' }} data-tip-discount>
              You will get a personal manager and a discount.
            </div>
            <div className="Calc__tip" data-notice />
            <div className="Calc__cell Calc__cell--pages">
              <label className="Calc__label">Pages</label>
              <div className="Calc__pages">
                <input
                  className="Calc__pages__input"
                  type="text"
                  name="pages"
                  data-pages-input
                  value="2"
                  maxLength="5"
                  onChange={() => {}}
                />
                <span className="Calc__label-item-words" data-words-qty="">
                  550 words
                </span>
                <div className="Calc__pages__buttons">
                  <button type="button" data-minus-button aria-label="decrease" />
                  <button type="button" data-plus-button aria-label="increase" />
                </div>
              </div>
            </div>
            <div className="Calc__footer">
              <div className="pay-parts">
                <label className="pay-parts__checkbox">
                  <input
                    ref={this.payInTwoCheckboxRef}
                    className="pay-parts__checkbox__input"
                    type="checkbox"
                    data-first-installment-input
                  />
                  <span className="pay-parts__checkbox__checkmark" />
                </label>
                <strong>Pay in 2 parts</strong>&nbsp;for your order!
                <div className="pay-parts__info" />
                <div className="pay-parts__inner">
                  <strong>You can Pay in 2 parts!</strong>
                  <p>
                    You can pay only 50% of the price when placing an order and submit the rest
                    after we finish your paper and deliver it to you. The{' '}
                    <a rel="nofollow" href="//customwritings.com/dashboard/orders/create?splitPayment=1">Pay in 2</a> service adds an
                    extra 5% to the price of your order.
                  </p>
                </div>
              </div>
              <div className="Calc__prices">
                <div className="Calc__first-installment">First installment:{' '}
                  <span className="Calc__first-installment-value" data-first-installment>$0</span>
                </div>
                <div className="Calc__total">
                  Total price:{' '}
                  <span className="Calc__total-value" data-total-price>$0</span>
                </div>
              </div>
              <button
                className="Calc__submit button button--red--white"
                type="submit"
                data-submit-button
              >
                Order paper
              </button>
              <div className="Calc__tip Calc__tip--deadline">
                <div className="Calc__tip__deadline">
                  We'll send you the first draft for approval by <span data-ext-time />
                </div>
              </div>
            </div>
          </div>
        </form>
        {!calcLoaded && <Spinner />}
      </div>
    );
  }
}

Calculator.propTypes = {
  academicLevel: PropTypes.number,
  paperType: PropTypes.number,
  pages: PropTypes.number,
};

Calculator.defaultProps = {
  academicLevel: 1,
  paperType: 2,
  pages: 2,
};

export default Calculator;
