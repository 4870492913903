import React from 'react';
import PropTypes from 'prop-types';

const Testimonial = ({
  id,
  comment,
  topicTitle,
  discipline,
}) => (
  <div className="testimonials__item">
    <div className="testimonials__item__id">ID {id}XXX</div>
    <div className="testimonials__item__body">{comment}</div>
    <div className="testimonials__item__topic">{topicTitle}</div>
    <div className="testimonials__item__discipline">{discipline}</div>
  </div>
);

Testimonial.propTypes = {
  id: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  topicTitle: PropTypes.string.isRequired,
  discipline: PropTypes.string.isRequired,
};

export default Testimonial;
