import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Splide.scss';

const SplideComponent = typeof window !== 'undefined' ? require('@splidejs/react-splide') : null;

// eslint-disable-next-line react/prefer-stateless-function
class Slider extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    if (!SplideComponent) return null;

    const {
      className,
      options,
      children,
      ref,
    } = this.props;

    const {
      Splide,
      SplideSlide,
    } = SplideComponent;

    return (
      <Splide
        options={options}
        className={className}
        ref={ref}
      >
        {
          // eslint-disable-next-line react/no-array-index-key
          children.map((slide, index) => <SplideSlide className={`${slide.key} slide-${index}`} key={`${slide.key} slide-${index}`}>{slide}</SplideSlide>)
        }
      </Splide>
    );
  }
}

Slider.propTypes = {
  ref: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

Slider.defaultProps = {
  ref: null,
  children: null,
  className: '',
};

export default Slider;
