import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from '../Slider';
import Testimonial from './Testimonial';
import './Testimonials.scss';

const options = {
  perPage: 3,
  type: 'loop',
  gap: '1.5rem',
  breakpoints: {
    768: {
      perPage: 1,
    },
    1024: {
      perPage: 2,
    },
  },
};

const Testimonials = ({ testimonials }) => (
  <div className="testimonials">
    <Slider options={options} className="testimonials-slider">
      {
        [].slice.call(testimonials).map(testimonial => (
          <Fragment key={testimonial.id}>
            <Testimonial {...testimonial} />
          </Fragment>
        ))
      }
    </Slider>
  </div>
);

Testimonials.propTypes = {
  testimonials: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default Testimonials;
